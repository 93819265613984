import { v1 } from "api"
import { ReactNode } from "react"
import { TFunction, useTranslation } from "react-i18next"
import { getNextPageParamV1 } from "src/bonzai/getNextPageParamV1"
import { TEXT_FALLBACK } from "src/constants"
import { FilterView, ProductList } from "ui/exports/portal"
import { QueryBoundary, WhenVisible } from "utility-components"
import { ProductCardLoader } from "./ProductCardLoader"
import { ProductCardsSkeleton } from "./ProductCardsSkeleton"

const ITEMS_PER_PAGE = 18

type FilterViewLoaderProps = {
  isMandatory: boolean
  isIncomplete: boolean
}
export const FilterViewLoader = ({
  isMandatory,
  isIncomplete,
}: FilterViewLoaderProps) => {
  const { t } = useTranslation()

  return (
    <FilterView>
      <FilterView.Top
        title={getTitle(t, { isIncomplete, isMandatory })}
        clearFilterText={t("product.CLEAR_FILTER")}
        clearFilterLink={{ to: "/for-you", search: {} }}
      />
      <QueryBoundary fallback={<ProductsSkeleton />}>
        <ProductsLoader isMandatory={isMandatory} isIncomplete={isIncomplete} />
      </QueryBoundary>
    </FilterView>
  )
}

const ProductsLoader = (props: FilterViewLoaderProps) => {
  const productsData = useData(props)
  const { data, fetchNextPage, isFetching } = productsData

  const nextPageCount = ITEMS_PER_PAGE
  const skeletonCount = isFetching ? nextPageCount : 0

  const products = data.pages.flatMap((page) => page.data)
  const productElements = products.map((product) => (
    <ProductCardLoader
      key={product.id}
      productId={product.id}
      productImage={product.image}
      productTitle={product.title ?? TEXT_FALLBACK}
      productType={product.identifier}
      isScorable={product.scorable}
      apiVersion="v1"
    />
  ))

  return (
    <>
      <ProductsSkeleton count={skeletonCount}>
        {productElements}
      </ProductsSkeleton>
      <WhenVisible whenVisible={fetchNextPage} key={data.pages.length} />
    </>
  )
}

type ProductsSkeletonProps = {
  count?: number
  children?: ReactNode
}
const ProductsSkeleton = ({
  children,
  count = ITEMS_PER_PAGE,
}: ProductsSkeletonProps) => (
  <ProductList>
    {children}
    <ProductCardsSkeleton count={count} />
  </ProductList>
)

const getTitle = (
  t: TFunction,
  { isIncomplete, isMandatory }: FilterViewLoaderProps
) => {
  if (isIncomplete && isMandatory) return t("user.INCOMPLETE_AND_MANDATORY")
  if (isMandatory) return t("user.MANDATORY")
  if (isIncomplete) return t("user.INCOMPLETE")
  throw new Error("Must be either complete or mandatory")
}

// Wait until v3 is ready
// const useData = ({ isMandatory, isIncomplete }: FilterViewLoaderProps) => {
//   return v3.getProducts.useInfiniteQuery(
//     [
//       {
//         "filter[policy_scope]": isMandatory ? "mandatory" : undefined,
//         "filter[certified]": isIncomplete ? false : undefined,
//         "page[size]": ITEMS_PER_PAGE,
//       },
//     ],
//     { getNextPageParam: getNextPageParamV3 }
//   )
// }

const useData = ({}: FilterViewLoaderProps) => {
  const { i18n } = useTranslation()
  return v1.getMandatoryProducts.useInfiniteQuery(
    [{ per_page: ITEMS_PER_PAGE, locale: i18n.language }],
    { getNextPageParam: getNextPageParamV1 }
  )
}
