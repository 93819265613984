import { v1, v3 } from "api"
import { getIsMandatory } from "src/dataUtilities/productDataUtilities"

type GetProductProgressOptions = {
  apiVersion: "v1" | "v3"
}

type ProductProgress = {
  stars: number
  stars_requirement: number
  certified: boolean
  rank: number
  high_score: number
  is_mandatory: boolean
  play_count: number
  percentage: number
}

/**
 * Provides a unified api for v1 and v3. Useful during migration where both endpoints are used
 */
export const getProductProgress = async (
  productId: number,
  { apiVersion }: GetProductProgressOptions
) => {
  if (apiVersion === "v1") {
    return getProductProgressV1(productId)
  } else {
    return getProductProgressV3(productId)
  }
}

const getProductProgressV1 = async (productId: number) => {
  const { status } = await v1.getProductStatus(productId)

  return {
    certified: status.certified ?? false,
    stars: status.stars,
    stars_requirement: status.achievable_stars,
    is_mandatory: getIsMandatory(status),
    play_count: status.play_count,
    rank: status.rank,
    high_score: status.high_score,
    percentage: status.percentage,
  } satisfies ProductProgress
}

const getProductProgressV3 = async (productId: number) => {
  const [{ data: progress }, { data: product }] = await Promise.all([
    v3.getProductProgress.fetchQuery([productId]),
    v3.getCurrentUserProduct.fetchQuery([productId]),
  ])

  return {
    certified: progress.certified,
    stars: progress.stars,
    is_mandatory: product.mandatory,
    stars_requirement: product.stars_requirement,

    // TODO. Add proper values when api is ready
    play_count: -1,
    rank: -1,
    high_score: -1,
    percentage: -1,
  } satisfies ProductProgress
}
