import { getConfig } from "./config"
import { loginNewSession, loginWithLockedSubdomain } from "./login"
import { getSessionData } from "./sessionData"
import { addSession, getSessions } from "./storage"
import { addSubdomainToStorage, getSubdomainFromStorage } from "./subdomain"
import { transferKeyToAccessToken } from "./transferKey"

export type Session = {
  accessToken: string
  companyNameKey: string
  subdomain: string
  userId: number
  logo: string
  pickedLocale: string | undefined
  impersonation: boolean
}

let sessionPromise: Promise<Session> | undefined = undefined

export const getActiveSession = async () => {
  if (sessionPromise) return sessionPromise

  sessionPromise = readActiveSession()

  const session = await sessionPromise
  addSubdomainToStorage(session.subdomain)
  return session
}

const readActiveSession = async (): Promise<Session> => {
  const { subdomain, rememberMe, transferKey, pickedLocale, impersonation } =
    await getConfig()

  if (subdomain && transferKey) {
    const accessToken = await transferKeyToAccessToken(transferKey)
    if (accessToken) {
      return createNewSession({
        accessToken,
        rememberMe,
        subdomain,
        pickedLocale,
        impersonation,
      })
    }
  }

  const sessions = await getSessions()

  if (subdomain) {
    const session = sessions.find((session) => session.subdomain === subdomain)
    return session ?? loginWithLockedSubdomain(subdomain)
  } else {
    const session = sessions.find(
      (session) => session.subdomain === getSubdomainFromStorage()
    )
    return session ?? sessions[0] ?? loginNewSession()
  }
}

type CreateNewSession = {
  accessToken: string
  rememberMe: boolean
  subdomain: string
  pickedLocale: string | undefined
  impersonation: boolean
}
const createNewSession = async ({
  accessToken,
  rememberMe,
  subdomain,
  pickedLocale,
  impersonation,
}: CreateNewSession): Promise<Session> => {
  const data = await getSessionData({ accessToken, subdomain })
  const session: Session = {
    accessToken: accessToken,
    companyNameKey: data.companyNameKey,
    subdomain: subdomain,
    userId: data.userId,
    logo: data.logo,
    pickedLocale,
    impersonation,
  }
  await addSession({ session, rememberMe })
  return session
}
