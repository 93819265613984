import { v1 } from "api"
import { useQueryParam } from "hooks"
import { useTranslation } from "react-i18next"
import { UserInfoHeader } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

export const UserInfoHeaderLoader = () => (
  <QueryBoundary fallback={<UserInfoHeader.Skeleton />}>
    <Load />
  </QueryBoundary>
)

const Load = () => {
  const { mandatory, stars, certified } = v1.getUserStatus.useQuery()
  const { t } = useTranslation()

  const [mandatoryQuery] = useQueryParam("mandatory")
  const isMandatory = mandatoryQuery === "true"

  return (
    <UserInfoHeader>
      <UserInfoHeader.Row>
        <UserInfoHeader.Completed
          label={t("user.COMPLETED")}
          value={isMandatory ? mandatory.count : certified.count}
          maxValue={isMandatory ? mandatory.of : certified.of}
          isActive={false}
        />
        <UserInfoHeader.Stars
          value={isMandatory ? mandatory.stars_count : stars.count}
          maxValue={isMandatory ? mandatory.stars_of : stars.of}
        />
        <UserInfoHeader.Mandatory
          label={t("user.MANDATORY")}
          value={mandatory.count}
          maxValue={mandatory.of}
          isVisible={mandatory.of > 0}
          link={{ to: "/for-you", search: { mandatory: !isMandatory } }}
          isActive={isMandatory}
        />
      </UserInfoHeader.Row>
      <UserInfoHeader.Progress
        value={isMandatory ? mandatory.count : certified.count}
        maxValue={isMandatory ? mandatory.of : certified.of}
        variant={isMandatory ? "mandatory" : "completed"}
      />
    </UserInfoHeader>
  )
}
