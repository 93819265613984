import { v3 } from "api"

type ProductType = v3["getProduct"]["data"]["product_type"]["identifier"]

export const isHandledByVuplex = (productType: ProductType) => {
  const typesHandledByVuplex: ProductType[] = [
    "act",
    "app",
    "behavior",
    "file",
    "link",
    "operations",
    "pdf",
    "process",
    "skill",
    "workflow",
    "xapi",
  ]
  return typesHandledByVuplex.includes(productType)
}
