import arrowDown from "assets/icons/arrowDown.svg"
import chevronIcon from "assets/icons/chevronIcon.svg"
import completedIcon from "assets/icons/completedIcon.svg"
import grayStarIcon from "assets/icons/grayStarIcon.svg"
import informationIcon from "assets/icons/informationIcon.svg"
import { ReactComponent as PlayButton } from "assets/icons/playButton.svg"
import starModalEmpty from "assets/icons/starModalEmpty.svg"
import starModalFilled from "assets/icons/starModalFilled.svg"
import { useWindowWidth } from "hooks"
import { times } from "lodash-es"
import { PropsWithChildren, ReactNode } from "react"
import { modifiers } from "ui-tools"
import { FiestaLink, FiestaLinkProps } from "utility-components"
import { sanitizeHtml } from "../../../utils/sanitizeHtml"
import { Loader } from "../../base/Loader"
import { ProductModalShell } from "../ProductModalShell"
import s from "./ProductModal.module.scss"

type Props = {
  children: ReactNode

  description: string
  image: string
  productType: string
  score: string
  stars: number
  starsMax: number
  starsRequirement: number
  title: string

  isCertified: boolean
  isMandatory: boolean
  isProductTypeVisible: boolean
  isReadMoreVisible: boolean
  isStarsRequirementVisible: boolean
  isScoreVisible: boolean

  mandatoryText: string
  readMoreText: string
  scoreText: string
  toCompleteText: string

  toggleReadMore: () => void
}

export const ProductModal = (props: Props) => {
  const {
    isCertified,
    isProductTypeVisible,
    isMandatory,
    isStarsRequirementVisible,
    isScoreVisible,
    children,
  } = props

  return (
    <ProductModalShell.Main>
      <div className={s.productModal}>
        <Primary {...props}>
          <Badges>
            {isCertified && <Certified />}
            {isProductTypeVisible && <ProductType {...props} />}
            {isMandatory && <Mandatory {...props} />}
            {isStarsRequirementVisible && <StarsRequirement {...props} />}
          </Badges>
          <Image {...props} />
          <Bottom>
            <Stars {...props} />
            <Title {...props} />
            {isScoreVisible && <Score {...props} />}
            <Description {...props} />
            <Buttons>{children}</Buttons>
          </Bottom>
        </Primary>
        <ReadMore {...props} />
      </div>
    </ProductModalShell.Main>
  )
}

const Primary = ({ children, isReadMoreVisible }: Props) => (
  <div className={modifiers(s, "primary", { isVisible: !isReadMoreVisible })}>
    {children}
  </div>
)

const Badges = ({ children }: PropsWithChildren) => (
  <div className={s.badges}>{children}</div>
)

const Certified = () => (
  <img className={s.certifiedBadge} src={completedIcon} alt="" />
)

const ProductType = ({ productType }: Props) => (
  <div className={s.badge}>{productType}</div>
)

const Mandatory = ({ mandatoryText }: Props) => (
  <div className={s.badge}>{mandatoryText}</div>
)

const StarsRequirement = ({ starsRequirement, toCompleteText }: Props) => (
  <div className={modifiers(s, "badge", "gray")}>
    {starsRequirement}
    <img src={grayStarIcon} alt="" />
    {toCompleteText}
  </div>
)

const Image = ({ image }: Props) => (
  <img className={s.image} src={image} alt="" />
)

const Bottom = ({ children }: PropsWithChildren) => (
  <div className={s.bottom}>{children}</div>
)

const Stars = ({ starsMax, stars }: Props) => {
  const elements = times(starsMax, (index) => {
    const isFilled = index < stars
    const src = isFilled ? starModalFilled : starModalEmpty
    return <img src={src} alt="" key={index} />
  })

  return <div className={s.stars}>{elements}</div>
}

const Title = ({ title }: Props) => <div className={s.title}>{title}</div>

const Score = ({ scoreText, score }: Props) => {
  const { isBigWindow } = useWindowWidth()
  if (isBigWindow) return null

  return (
    <div className={s.score}>
      <div className={s.score__numbers}>{score}</div>
      <div className={s.score__pointsText}>{scoreText}</div>
    </div>
  )
}

const Description = ({ description, readMoreText, toggleReadMore }: Props) => {
  const MAX_LENGTH = 175
  const shortDescription = description.slice(0, MAX_LENGTH)
  const isButtonVisible = shortDescription !== description

  return (
    <p className={s.description}>
      <span dangerouslySetInnerHTML={sanitizeHtml(shortDescription)} />
      {isButtonVisible && (
        <button className={s.description__showMore} onClick={toggleReadMore}>
          ...{readMoreText}
        </button>
      )}
    </p>
  )
}

const Buttons = ({ children }: PropsWithChildren) => (
  <ProductModalShell.ActionButtons>
    <div className={s.buttons}>{children}</div>
  </ProductModalShell.ActionButtons>
)

type PlayLinkProps = FiestaLinkProps & {
  children: ReactNode
  iconVariant?: "play" | "download"
  linkVariant?: "green" | "white"
}
const PlayLink = ({
  iconVariant = "play",
  linkVariant = "green",
  children,
  ...props
}: PlayLinkProps) => (
  <FiestaLink className={modifiers(s, "playLink", linkVariant)} {...props}>
    {iconVariant === "play" ? (
      <PlayButton className={s.playLink__icon} />
    ) : (
      <img className={s.playLink__icon} src={arrowDown} alt="" />
    )}
    {children}
  </FiestaLink>
)

type HelpIconProps = {
  onClick: () => void
}
const HelpIcon = ({ onClick }: HelpIconProps) => (
  <button onClick={onClick} className={s.helpIcon}>
    <img src={informationIcon} alt="Help" />
  </button>
)

const LanguageDropdown = ({ children }: PropsWithChildren) => (
  <div className={s.languageDropdown}>{children}</div>
)

type NotSupportedProps = {
  title: string
  supportedBy: string
  platforms: string
}
const NotSupported = ({ title, supportedBy, platforms }: NotSupportedProps) => (
  <div className={s.notSupported}>
    <div className={s.notSupported__title}>{title}</div>
    <div className={s.notSupported__supportedBy}>
      {supportedBy}{" "}
      <span className={s.notSupported__platforms}>{platforms}</span>
    </div>
  </div>
)

const ReadMore = ({
  title,
  description,
  toggleReadMore,
  isReadMoreVisible,
}: Props) => (
  <div className={modifiers(s, "readMore", { isVisible: isReadMoreVisible })}>
    <header className={s.readMore__header}>
      <button className={s.readMore__headerBackButton} onClick={toggleReadMore}>
        <img src={chevronIcon} alt="Go back" />
      </button>
      <div className={s.readMore__headerTitle}>{title}</div>
    </header>
    <p className={s.readMore__paragraph}>
      <span dangerouslySetInnerHTML={sanitizeHtml(description)} />
    </p>
  </div>
)

const Skeleton = () => (
  <ProductModalShell.Main>
    <div className={s.skeleton}>
      <Loader delay={200} />
    </div>
  </ProductModalShell.Main>
)

ProductModal.PlayLink = PlayLink
ProductModal.LanguageDropdown = LanguageDropdown
ProductModal.NotSupported = NotSupported
ProductModal.HelpIcon = HelpIcon
ProductModal.Skeleton = Skeleton
