import { Navigate } from "@tanstack/react-location"
import { v3 } from "api"

type CompanySettings = v3["getCurrentCompanySettings"]

export const FrontPageLoader = () => {
  const companySettings = v3.getCurrentCompanySettings.useQuery()

  return <Navigate replace to={getLandingPage(companySettings)} />
}

const getLandingPage = (companySettings: CompanySettings) => {
  const landingPage = companySettings.data.default_portal_landing_page

  switch (landingPage) {
    case "for_you":
      return "for-you"
    default:
      return landingPage
  }
}
