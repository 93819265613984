import { DEVICE_PLATFORM } from "src/constants"

export const getLauncherLink = () => {
  switch (DEVICE_PLATFORM.platform) {
    case "Windows":
      return "https://webcontent.attensi.com/download/attensi-launcher.pkg"
    case "MacOS":
      return "https://webcontent.attensi.com/download/attensi-launcher.msi"
    default:
      throw new Error(
        `Cannot provide launcher link on ${DEVICE_PLATFORM.platform}`
      )
  }
}
