import { useMatch } from "@tanstack/react-location"
import { v1 } from "api"
import { useQueryAll } from "bonzai"
import { useState } from "react"
import { custom } from "src/bonzai/bonzai"
import { createPlaythrough } from "src/bonzai/createPlaythrough"
import {
  getMaxStarsForProduct,
  getProductLink,
} from "src/dataUtilities/productDataUtilities"
import { usePickText } from "src/i18n/usePickText"
import { useSubscribeToVuplex } from "src/vuplex/subscribeToVuplex"
import { ProductPlaying } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

type Product = v1["getProduct"]

export const ProductPlayingLoader = () => {
  const productId = useVuplexState()
  if (productId === undefined) return null

  return (
    <QueryBoundary>
      <Load productId={productId} />
    </QueryBoundary>
  )
}

type ProductProps = {
  productId: number
}
const Load = ({ productId }: ProductProps) => {
  const [product, status] = useData(productId)
  const { product_type, no_score, image, titles } = product

  const pickText = usePickText()

  const link = useProductLink(product)

  return (
    <ProductPlaying
      link={link}
      image={image}
      progress={status.percentage}
      progressMax={100}
      stars={status.stars}
      starsMax={getMaxStarsForProduct(product_type, !no_score)}
      title={pickText(titles)}
    />
  )
}

const useData = (productId: number) => {
  return useQueryAll(() => [
    v1.getProduct.useQuery([productId]),
    custom.getProductProgress.useQuery([productId, { apiVersion: "v1" }]),
  ])
}

const useProductLink = (product: Product) => {
  const relativeLink = getProductLink(product.id, product.product_type)

  // Couldn't find a way to do this with the actual router library.
  // It refused to do relative links when AppShellLoader is used outside the outlet.
  const to = `${window.location.pathname}/${relativeLink}`

  // This hook triggers a rerender whenever the route changes.
  // This is needed since we read pathname from window directly instead of from the library.
  useMatch()

  return { to }
}

const useVuplexState = () => {
  const [productId, setProductId] = useState<number | undefined>()

  useSubscribeToVuplex("SET_MINI_MODAL_PRODUCT_ID", (message) => {
    setProductId(message.payload)
  })

  useSubscribeToVuplex("CLOSE_PRODUCT_MODALS", () => {
    setProductId(undefined)
  })

  useSubscribeToVuplex("REFRESH_PRODUCT_SCORE", () => {
    if (productId === undefined) return

    createPlaythrough(productId, {
      mutate: false,
      invalidate: true,
      invalidateDependencies: true,
    })
  })

  return productId
}
