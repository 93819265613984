import { v3 } from "api"
import { useTranslation } from "react-i18next"
import { usePickText } from "src/i18n/usePickText"
import { NewsWidget } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

export const NewsWidgetLoader = () => {
  const { t } = useTranslation()

  return (
    <QueryBoundary fallback={<NewsWidget.Skeleton />}>
      <NewsWidget headerText={t("news.NEWS")}>
        <Load />
      </NewsWidget>
    </QueryBoundary>
  )
}

const Load = () => {
  const { data } = v3.getNewsPosts.useQuery([{ "page[size]": 3 }])
  const pickText = usePickText()
  const { t } = useTranslation()

  if (data.length === 0) {
    return <NewsWidget.Empty emptyText={t("notFound.NOTFOUND_NEWS")} />
  }

  const items = data.map((item) => (
    <NewsWidget.Item
      link={{ to: `/news/post/${item.id}` }}
      key={item.id}
      title={pickText(item.headline)}
      description={pickText(item.body)}
      image={item.image?.file_url ?? ""}
    />
  ))

  return <>{items}</>
}
