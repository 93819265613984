import { v1 } from "api"

type Params = {
  per_page?: number
  page?: number
}

/**
 * Adds pagination to getPlayableProductIds to make it easier to use with useInfiniteQuery.
 */
export const getPlayableProductIds = async ({
  per_page = 10,
  page = 1,
}: Params = {}) => {
  const productIds = await v1.getPlayableProductIds()

  const from = (page - 1) * per_page
  const to = page * per_page
  const data = productIds.data.slice(from, to)

  const count = productIds.data.length
  const hasMore = to < count
  const next = hasMore ? page + 1 : null

  return { data, pagination: { count, next } }
}
