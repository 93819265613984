import { createResolvable } from "utils"

export type AuthConfig = {
  authUrl: string
  backendApiToken: string
  backendUrl: string
  loginUrl: string
  pickedLocale: string | undefined
  subdomain: string | undefined
  transferKey: string | undefined
  rememberMe: boolean
  impersonation: boolean
}

const { promise, resolve } = createResolvable<AuthConfig>()

export const getConfig = () => promise
export const setConfig = resolve
