import { v1 } from "api"
import { useTranslation } from "react-i18next"
import { custom } from "src/bonzai/bonzai"
import { TEXT_FALLBACK } from "src/constants"
import { formatNumber } from "src/dataUtilities/formatNumber"
import {
  getIsAttensiProduct,
  getMaxStarsForProduct,
  getProductLink,
} from "src/dataUtilities/productDataUtilities"
import { FeaturedProducts } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

type Category = v1["getProductCategories"]["data"][number]
type Product = v1["getProductsInCategory"]["data"][number]

type FeaturedProductsLoaderProps = {
  category: Category
}
export const FeaturedProductsLoader = (props: FeaturedProductsLoaderProps) => (
  <QueryBoundary fallback={<FeaturedProducts.Skeleton />}>
    <Load {...props} />
  </QueryBoundary>
)

const Load = ({ category }: FeaturedProductsLoaderProps) => {
  const products = useProducts(category.id)

  const productElements = products.map((product) => (
    <FeaturedProductLoader key={product.id} product={product} />
  ))

  return <FeaturedProducts>{productElements}</FeaturedProducts>
}

type FeaturedProductLoaderProps = {
  product: Product
}
const FeaturedProductLoader = ({ product }: FeaturedProductLoaderProps) => {
  const { i18n, t } = useTranslation()

  const status = custom.getProductProgress.useQuery(
    [product.id, { apiVersion: "v1" }],
    { suspense: false }
  )

  return (
    <FeaturedProducts.Product
      link={{ to: getProductLink(product.id, product.identifier) }}
      image={product.image}
      title={product.title ?? TEXT_FALLBACK} // TODO: use pickText with v3
      labelText={t("product.FEATURED_MAINTITLE")}
      isCertifiedVisible={status?.certified ?? false}
      isMandatoryVisible={status?.is_mandatory ?? false}
      mandatoryText={t("user.MANDATORY")}
      starsMax={getMaxStarsForProduct(product.identifier, product.scorable)}
      stars={status?.stars ?? 0}
      isScoreVisible={getIsAttensiProduct(product.identifier)}
      scoreText={t("product.SCORE")}
      score={formatNumber(i18n.language, status?.high_score ?? 0)}
    />
  )
}

const useProducts = (category_id: number) => {
  const { i18n } = useTranslation()

  return v1.getProductsInCategory.useQuery(
    [{ category_id, locale: i18n.language, per_page: 20 }],
    { select: (res) => res.data }
  )
}
