import { getQueryParams } from "utils"
import { setConfig } from "./config"
import { removeQueryParams } from "./utils/removeQueryParams"

type InitializeAuth = {
  authUrl: string
  backendApiToken: string
  backendUrl: string
  loginUrl: string
  subdomain: string | undefined
}

export const initializeAuth = (args: InitializeAuth): void => {
  const { remember_me, transfer_key, subdomain, picked_locale, impersonation } =
    getQueryParams()

  const subdomainFromUrl = subdomain ?? args.subdomain

  removeQueryParams([
    "access_token",
    "api_token",
    "company_name_key",
    "locale",
    "picked_locale",
    "remember_me",
    "subdomain",
    "transfer_key",
    "impersonation",
  ])

  setConfig({
    authUrl: args.authUrl,
    backendApiToken: args.backendApiToken,
    backendUrl: args.backendUrl,
    loginUrl: args.loginUrl,
    pickedLocale: picked_locale,
    subdomain: subdomainFromUrl,
    transferKey: transfer_key,
    rememberMe: remember_me === "true",
    impersonation: impersonation === "true",
  })
}
