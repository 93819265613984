import { string, type } from "superstruct"
import { blobToJson } from "utils"
import { wrapFetch } from "wrap-fetch"
import { getConfig } from "./config"

const TransferTokenResponse = type({
  TransferKey: string(),
})

export const accessTokenToTransferKey = async (accessToken: string) => {
  const { authUrl } = await getConfig()
  const data = await wrapFetch({
    baseUrl: authUrl,
    path: "/api/v1/sessions/transfer",
    method: "PUT",
    transform: (blob) => blobToJson(blob, TransferTokenResponse),
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  return data.TransferKey
}

const TokenResponse = type({
  Token: string(),
})

export const transferKeyToAccessToken = async (transferKey: string) => {
  const { authUrl } = await getConfig()
  const data = await wrapFetch({
    baseUrl: authUrl,
    method: "GET",
    path: `/api/v1/sessions/transfer/${transferKey}`,
    transform: (blob) => blobToJson(blob, TokenResponse),
  })
  return removeBearerPrefix(data.Token)
}

const removeBearerPrefix = (value: string) => {
  return value.replace(/bearer\s*/i, "").trim()
}
