import { v3 } from "api"

type CompanySettings = v3["getCurrentCompanySettings"]["data"]
type Flag = CompanySettings["portal_configurations"][number]
type PortalConfigurations = Record<Flag, boolean>

/**
 * Makes it easier to work with the portal configuration flags by creating an object with a boolean property for each flag.
 *
 * Example:
 * {
 *   disable_leaderboard: true,
 *   enable_portal_news: false,
 * }
 */
export const getPortalConfigurations = async () => {
  const { data: settings } = await v3.getCurrentCompanySettings.fetchQueryOnce()

  const flags = Object.fromEntries(
    settings.portal_configurations.map((flag) => [flag, true])
  )

  // Use proxy to fallback to false for undefined flags.
  return new Proxy(flags, {
    get: getPortalConfiguration,
  }) as PortalConfigurations
}

const getPortalConfiguration = (
  configurations: Partial<PortalConfigurations>,
  flag: keyof PortalConfigurations
) => {
  return configurations[flag] ?? false
}
