import { bonzai } from "bonzai"
import { number, string, type } from "superstruct"
import { blobToJson } from "utils"
import { wrapFetch } from "wrap-fetch"
import { getConfig } from "./config"

/**
 * This file is temporory,
 * and emulates what might be stored in auth/session service in the future
 */

type GetSessionDataParams = {
  accessToken: string
  subdomain: string
}
export const getSessionData = async ({
  accessToken,
  subdomain,
}: GetSessionDataParams) => {
  const [user, company] = await Promise.all([
    api.getUser.fetchQuery([{ accessToken, subdomain }]),
    api.getCompany.fetchQuery([{ accessToken, subdomain }]),
  ])
  return {
    accessToken: accessToken,
    companyNameKey: company.name_key,
    subdomain: subdomain,
    userId: user.id,
    logo: company.logo,
  }
}

const UserResponse = type({
  id: number(),
})

const getUser = async ({ accessToken, subdomain }: GetSessionDataParams) => {
  const { backendUrl, backendApiToken } = await getConfig()
  return wrapFetch({
    baseUrl: backendUrl,
    path: `/api/v1/users/me`,
    transform: (blob) => blobToJson(blob, UserResponse),
    headers: {
      "API-Token": backendApiToken,
      Authorization: `Bearer ${accessToken}`,
      subdomain: subdomain,
    },
  })
}

const CompanyResponse = type({
  logo: string(),
  name_key: string(),
})

const getCompany = async ({ accessToken, subdomain }: GetSessionDataParams) => {
  const { backendUrl, backendApiToken } = await getConfig()

  return wrapFetch({
    baseUrl: backendUrl,
    path: `/api/v1/public/companies/current`,
    transform: (blob) => blobToJson(blob, CompanyResponse),
    headers: {
      "API-Token": backendApiToken,
      Authorization: `Bearer ${accessToken}`,
      subdomain: subdomain,
    },
  })
}

// Use bonzai for automatic retries
const api = bonzai({
  getUser,
  getCompany,
})
