import {
  array,
  boolean,
  enums,
  lazy,
  literal,
  nullable,
  number,
  optional,
  record,
  string,
  Struct,
  type,
  union,
} from "superstruct"

export const UserRole = enums([
  "admin",
  "external_tester",
  "manager",
  "superadmin",
  "superuser",
  "supervisor",
  "tester",
] as const)

export const ProductType = enums([
  "act",
  "activity",
  "app",
  "behavior",
  "fast",
  "file",
  "html", // Deprecated.
  "link",
  "meet",
  "operations",
  "pdf",
  "process",
  "quiz_race",
  "scorm", // Deprecated.
  "sim", // Deprecated.
  "skill",
  "third_party", // Deprecated.
  "video",
  "workflow",
  "xapi",
] as const)

export const PortalConfigurationFlag = enums([
  "disable_completed_courses_stars",
  "disable_global_leaderboard",
  "disable_leaderboard",
  "disable_top_menu",
  "disable_user_profile",
  "enable_activity_feed",
  "enable_kpi_sales_tiles",
  "enable_learning_journey",
  "enable_portal_background",
  "enable_portal_news",
  "enable_beta_portal",
  "portal_onboarding",
] as const)

export const TranslatedTexts = record(string(), nullable(string()))

export const Locale = type({
  code: string(),
  name: string(),
})

export const Company = type({
  id: number(),
  logo: string(),
  name: string(),
  name_key: string(),
  attensi_com_subdomain: string(),
  portal_configurations: array(PortalConfigurationFlag),
  portal_background_url: nullable(string()),
  portal_supported_locales: array(Locale),
  default_locale: string(),
  privacy_policy: string(),
  user_display_name_format: enums([
    "username",
    "firstname",
    "firstname_and_lastname",
  ] as const),
  privacy_policies: array(
    type({
      locale: string(),
      language: string(),
      html_url: string(),
      text_url: string(),
    })
  ),
})

export const Status = type({
  achievable_stars: number(),
  certification_requirement: number(),
  certified: nullable(boolean()),
  certified_count: number(),
  high_score: number(),
  mandatory_count: number(),
  mandatory_of: number(),
  percentage: number(),
  play_count: number(),
  rank: number(),
  stars: number(),
  total_score: number(),
})

export const StatusResponse = type({
  status: Status,
})

export const CreateProductResponse = type({
  company_id: number(),
  content_link: string(),
  description_long: TranslatedTexts,
  description_short: TranslatedTexts,
  id: number(),
  manager_approval: boolean(),
  product_type: ProductType,
  properties: record(string(), string()),
  self_serve: boolean(),
  title: TranslatedTexts,
  token: nullable(string()),
})

export const Product = type({
  id: number(),
  image: string(),
  company_id: number(),
  product_type: ProductType,
  title: nullable(string()),
  titles: TranslatedTexts,
  description: nullable(string()),
  descriptions: nullable(TranslatedTexts),
  description_long: nullable(TranslatedTexts),
  is_attensi_type: boolean(),
  playable: boolean(),
  no_score: boolean(),
  status: Status,
  content_link: string(),
  webgl_content_link: string(),
  webgl_responsive: boolean(),
  supported_platforms: array(string()),
  token: nullable(string()),
  google_play_link: string(),
  google_play_test_link: nullable(string()),
  manager_approval: boolean(),
  apple_store_link: string(),
  test_flight_link: nullable(string()),
  has_vpp_codes: boolean(),
  launcher_link: string(),
  properties: type({
    open_external_browser: optional(enums(["true", "false"] as const)),
  }),
  type: type({
    identifier: ProductType,
    open_with: enums(["launcher", "href"] as const),
  }),
  scenarios: array(
    type({
      id: number(),
      token: string(),
    })
  ),
  schedule: type({
    id: number(),
    token: string(),
  }),
  uploads: array(
    type({
      id: number(),
      locale: string(),
      file_url: string(),
    })
  ),
})

export const User = type({
  id: number(),
  username: nullable(string()),
  image: string(),
  email: nullable(string()),
  position: nullable(string()),
  first_name: string(),
  last_name: string(),
  phone_number: string(),
  anonymous: boolean(),
  department: nullable(string()),
  roles: array(UserRole),
})

export const Avatar = type({
  id: number(),
  title: string(),
  url: string(),
})

export const AvatarsResponse = array(
  type({
    id: number(),
    title: string(),
    description: string(),
    avatars: array(Avatar),
  })
)

export const ActivityItem = type({
  user_id: number(),
  image: string(),
  first_name: string(),
  last_name: string(),
  anonymous: boolean(),
  product: record(string(), string()),
  timestamp: string(),
  prev_stars: optional(string()),
  new_stars: optional(string()),
  prev_rank: optional(string()),
  new_rank: optional(string()),
  status: optional(string()),
})

export const ActivityFeedResponse = type({
  stars: union([ActivityItem, type({})]),
  leaderboard: union([ActivityItem, type({})]),
  completed: union([ActivityItem, type({})]),
})

export const UserStatus = type({
  certified: type({
    count: number(),
    of: number(),
    percentage: number(),
  }),
  mandatory: type({
    count: number(),
    of: number(),
    percentage: number(),
    stars_count: number(),
    stars_of: number(),
  }),
  stars: type({
    count: number(),
    of: number(),
  }),
})

export const ProductCategory = type({
  id: number(),
  title: nullable(string()),
  background_image_url: nullable(string()),
  palp_only: boolean(),
  featured: boolean(),
  journey: boolean(),
})

export const Pagination = type({
  current: number(),
  previous: nullable(number()),
  next: nullable(number()),
  per_page: number(),
  pages: number(),
  count: number(),
})

export const ProductCategoriesResponse = type({
  data: array(ProductCategory),
  pagination: Pagination,
})

export const ProductInCategory = type({
  id: number(),
  title: nullable(string()),
  description: nullable(string()),
  identifier: ProductType,
  image: string(),
  is_all_platforms_type: boolean(),
  is_attensi_type: boolean(),
  is_playable: boolean(),
  scorable: boolean(),
  third_party_content: boolean(),
})

export const ProductsInCategoryResponse = type({
  data: array(ProductInCategory),
  pagination: Pagination,
})

export const MandatoryProductsResponse = type({
  data: array(ProductInCategory),
  pagination: Pagination,
})

export const ExploreProductTypesResponse = type({
  data: array(ProductType),
  pagination: Pagination,
})

export const ProductPlatformDetails = type({
  id: number(),
  title: nullable(string()),
  is_vpp_product: boolean(),
  label: ProductType,
  download_link: nullable(string()),
  play_link: nullable(string()),
  is_downloadable: boolean(),
  supported_platforms: array(string()),
  playthrough_tokens: nullable(
    type({
      scenario_token: string(),
      schedule_token: string(),
    })
  ),
})

export const HighScoreEntry = type({
  rank: number(),
  value: number(),
  user: type({
    image: string(),
    username: nullable(string()),
    first_name: string(),
    last_name: nullable(string()),
  }),
})

export const HighScores = type({
  entries: array(HighScoreEntry),
  my_entry: HighScoreEntry,
})

export const MostPlayedProductsResponse = type({
  products: array(
    type({
      product_id: number(),
      playthrough_count: number(),
    })
  ),
})

export const PlayableProductIds = type({
  data: array(number()),
})

export const EventDate = type({
  attendees_count: number(),
  attendees_count_max: number(),
  end_datetime: string(),
  id: number(),
  instructor: string(),
  instructor_user: literal(null),
  is_attending: boolean(),
  location: string(),
  room: string(),
  start_datetime: string(),
  state: enums(["open", "canceled", "expired", "full"] as const),
})

export const EventDatesResponse = array(EventDate)

export const ToggleAttendingResponse = type({
  attending: boolean(),
  course_id: number(),
  user_id: number(),
})

export const UserNameIsAvailableResponse = type({
  available: boolean(),
  message: string(),
  current_username: nullable(string()),
})

export const VppCountriesResponse = array(
  type({
    country: string(),
    store: string(),
  })
)

export const VppCodeResponse = type({
  code: string(),
})

type Department = {
  name: string
  children: Department[]
  tags: string[]
}
const Department: Struct<Department> = lazy(() =>
  type({
    name: string(),
    children: array(Department),
    tags: array(string()),
  })
)
export const DepartmentsResponse = array(Department)

export const ProductsSearchResponse = type({
  data: array(
    type({
      created_at: string(),
      id: number(),
      image: string(),
      product_type: ProductType,
      title: nullable(string()),
      description: nullable(string()),
    })
  ),
  pagination: Pagination,
})

export const ProductPoliciesResponse = array(
  type({
    base_content: boolean(),
    company_id: number(),
    created_at: string(),
    description: nullable(string()),
    id: number(),
    name: string(),
    roles: array(UserRole),
    updated_at: string(),
    state: enums(["optional", "mandatory", "none"] as const),
  })
)

export const PoliciesResponse = array(
  type({
    base_content: boolean(),
    company_id: number(),
    created_at: string(),
    description: nullable(string()),
    id: number(),
    name: string(),
    roles: array(UserRole),
    updated_at: string(),
    type: enums(["default", "and_policy", ""] as const),
  })
)

export const GetProductCoursesAdminResponse = type({
  data: array(
    type({
      id: number(),
      attendees_count: number(),
      attendees_count_max: number(),
      end_datetime: string(),
      instructor: string(),
      location: string(),
      room: string(),
      start_datetime: string(),
      state: enums(["open", "canceled", "expired", "full"] as const),
    })
  ),
  pagination: Pagination,
})

export const GetUsersSearchResponse = type({
  data: array(
    type({
      id: number(),
      first_name: string(),
      last_name: string(),
      email: string(),
    })
  ),
  pagination: Pagination,
})
