import { v1, v3 } from "api"
import { useQueryAll } from "bonzai"
import { useWindowWidth } from "hooks"
import { sortBy } from "lodash-es"
import { useTranslation } from "react-i18next"
import { custom } from "src/bonzai/bonzai"
import { createPlaythrough } from "src/bonzai/createPlaythrough"
import { toggleActivityItem } from "src/bonzai/toggleActivityItem"
import { usePickText } from "src/i18n/usePickText"
import { ProductModalActivity } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

type ProductModalActivityLoaderProps = {
  productId: number
  onClose: () => void
}
export const ProductModalActivityLoader = (
  props: ProductModalActivityLoaderProps
) => (
  <QueryBoundary
    fallback={<ProductModalActivity.Skeleton />}
    isSuspense={useWindowWidth().isBigWindow}
  >
    <Load {...props} />
  </QueryBoundary>
)

const Load = ({ productId, onClose }: ProductModalActivityLoaderProps) => {
  const pickText = usePickText()
  const { t } = useTranslation()

  const [status, product, tasks] = useData(productId)

  const numberOfTasks = tasks.length
  const numberOfCompletedTasks = tasks.filter((item) => item.done).length
  const isLocked = status.stars > 0
  const isBottomVisible = numberOfCompletedTasks >= numberOfTasks && !isLocked

  const taskElements = tasks.map((task) => (
    <ProductModalActivity.Task
      key={task.id}
      title={pickText(task.title)}
      isChecked={task.done}
      isLocked={isLocked}
      onClick={() => toggleActivityItem(productId, task)}
    />
  ))

  const onConfirm = () => {
    onClose()
    createPlaythrough(productId, {
      optimisticOneStar: true,
      invalidateDependencies: true,
    })
  }

  return (
    <ProductModalActivity>
      <ProductModalActivity.Top
        isLocked={isLocked}
        completedCount={numberOfCompletedTasks}
        totalCount={numberOfTasks}
      />
      <ProductModalActivity.Tasks>{taskElements}</ProductModalActivity.Tasks>
      <ProductModalActivity.Bottom isVisible={isBottomVisible}>
        <ProductModalActivity.WaitingForApproval
          isVisible={product.manager_approval}
          title={t("activity.PENDING_APPROVAL")}
          tooltipTitle={t("activity.PENDING_APPROVAL_HELP_TITLE")}
          tooltipDescription={t("activity.PENDING_APPROVAL_HELP_DESCRIPTION")}
        />
        <ProductModalActivity.ConfirmButton
          isVisible={!product.manager_approval}
          confirmText={t("activity.CONFIRM_COMPLETE")}
          onClick={onConfirm}
        />
      </ProductModalActivity.Bottom>
    </ProductModalActivity>
  )
}

const useData = (productId: number) => {
  return useQueryAll(() => [
    custom.getProductProgress.useQuery([productId, { apiVersion: "v1" }]),

    v1.getProduct.useQuery([productId]),

    v3.getActivityItems.useQuery([productId], {
      select: (res) => sortBy(res.data, (item) => item.id),
    }),
  ])
}
