import { v1, v3 } from "api"
import icalGenerator from "ical-generator"
import { pickTextAsync } from "src/i18n/usePickText"
import { IS_APP } from "src/vuplex/constants"
import { sendVuplexMessage } from "src/vuplex/sendVuplexMessage"
import { downloadBlob } from "utils"

type Product = v1["getProduct"]
type Course = v3["getProductCourse"]["data"]

export const addToCalendar = async (product: Product, course: Course) => {
  if (IS_APP) {
    addToCalendarVuplex(product, course)
  } else {
    await addToCalendarIcal(product, course)
  }
}

const addToCalendarVuplex = async (product: Product, course: Course) => {
  sendVuplexMessage({
    type: "ADD_TO_CALENDAR",
    payload: {
      title: await pickTextAsync(product.titles),
      startDatetime: course.start_datetime,
      endDatetime: course.end_datetime,
      location: course.location,
      description: await pickTextAsync(product.descriptions, ""),
    },
  })
}

const addToCalendarIcal = async (product: Product, course: Course) => {
  const cal = icalGenerator()

  cal.createEvent({
    summary: await pickTextAsync(product.titles),
    start: course.start_datetime,
    end: course.end_datetime,
    location: course.location,
    description: await pickTextAsync(product.descriptions, ""),
  })

  const blob = cal.toBlob()
  const fileName = `calendar-invite-${new Date().toISOString()}.ics`
  downloadBlob(blob, fileName)
}
