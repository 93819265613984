import { v1 } from "api"

type User = v1["getMe"]

export const getHasAdminAccess = (user: User) => {
  const rolesWithAccess = new Set([
    "admin",
    "superadmin",
    "manager",
    "superuser",
    "supervisor",
  ])

  return user.roles.some((role) => rolesWithAccess.has(role))
}
