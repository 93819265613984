import { v3 } from "api"
import exploreIcon from "assets/icons/exploreIcon.svg"
import forYouIcon from "assets/icons/forYouIcon.svg"
import journeyIcon from "assets/icons/journeyIcon.svg"
import leaderboardIcon from "assets/icons/leaderboardIcon.svg"
import newsIcon from "assets/icons/newsIcon.svg"
import { useQueryAll } from "bonzai"
import { sortBy } from "lodash-es"
import { ComponentProps } from "react"
import { useTranslation } from "react-i18next"
import { custom } from "src/bonzai/bonzai"
import { Navigation } from "ui/exports/portal"

type LinkProps = ComponentProps<typeof Navigation.Link>
type CompanySettings = v3["getCurrentCompanySettings"]["data"]
type LandingPage = CompanySettings["default_portal_landing_page"]
type LinkWithName = LinkProps & { name: LandingPage }

export const NavigationLoader = () => {
  const { t } = useTranslation()

  const [companySettings, flags] = useData()

  if (flags.disable_top_menu) return null

  const linksWithName: LinkWithName[] = [
    {
      name: "for_you",
      href: "/for-you",
      text: t("navigation.FOR_YOU"),
      icon: forYouIcon,
    },
    {
      name: "journey",
      href: "/journey",
      text: t("navigation.JOURNEY"),
      icon: journeyIcon,
    },
    {
      name: "explore",
      href: "/explore",
      text: t("navigation.EXPLORE"),
      icon: exploreIcon,
    },
    {
      name: "leaderboards",
      href: "/leaderboards",
      text: t("navigation.LEADERBOARDS"),
      icon: leaderboardIcon,
    },
    {
      name: "news",
      href: "/news",
      text: t("navigation.NEWS"),
      icon: newsIcon,
    },
  ]

  const enabledLinks = linksWithName.filter(({ name }) => {
    if (name === "journey") return flags.enable_learning_journey
    if (name === "leaderboards") return !flags.disable_leaderboard
    if (name === "news") return flags.enable_portal_news
    return true
  })

  const landingPage = companySettings.default_portal_landing_page

  const sortedByLandingPage = sortBy(enabledLinks, (page) =>
    page.name === landingPage ? 0 : 1
  )

  const links = sortedByLandingPage.map(({ name: page, ...props }) => (
    <Navigation.Link key={page} {...props} />
  ))

  return <Navigation>{links}</Navigation>
}

const useData = () => {
  return useQueryAll(() => [
    v3.getCurrentCompanySettings.useQuery({
      select: (res) => res.data,
    }),

    custom.getPortalConfigurations.useQuery(),
  ])
}
