import { v1, v3 } from "api"
import { useQueryAll } from "bonzai"
import { useTranslation } from "react-i18next"
import { custom } from "src/bonzai/bonzai"
import { TEXT_FALLBACK } from "src/constants"
import {
  getIsAttensiProduct,
  getIsInProgress,
  getMaxStarsForProduct,
  getProductLink,
} from "src/dataUtilities/productDataUtilities"
import { JourneyCard } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

type Product = v1["getProductsInCategory"]["data"][number]

type JourneyCardLoaderProps = {
  product: Product
}
export const JourneyCardLoader = (props: JourneyCardLoaderProps) => (
  <QueryBoundary fallback={<JourneyCard.Skeleton />}>
    <Load {...props} />
  </QueryBoundary>
)

const Load = ({ product }: JourneyCardLoaderProps) => {
  const { t } = useTranslation()

  const [status, activityProgress] = useData(product)

  const isAttensiProduct = getIsAttensiProduct(product.identifier)
  const productType = t(`product.PRODUCT_TYPES.${product.identifier}`)

  return (
    <JourneyCard
      activityProgress={activityProgress?.progress_count ?? 0}
      activityProgressMax={activityProgress?.progress_of ?? 0}
      image={product.image}
      link={{ to: getProductLink(product.id, product.identifier) }}
      productType={productType}
      stars={status?.stars ?? 0}
      starsMax={getMaxStarsForProduct(product.identifier, product.scorable)}
      title={product.title ?? TEXT_FALLBACK} // TODO: use pickText with v3
      isActivityProgressVisible={product.identifier === "activity"}
      isCertified={status?.certified ?? false}
      isFastProduct={product.identifier === "fast"}
      isImageVisible={isAttensiProduct}
      isInProgress={getIsInProgress(product.identifier, status)}
      isMandatory={status?.is_mandatory ?? false}
      isProductTypeVisible={!isAttensiProduct}
      isStarsVisible={product.scorable}
      inProgressText={t("product.PRODUCT_IN_PROGRESS")}
      mandatoryText={t("user.MANDATORY")}
    />
  )
}

const useData = (product: Product) => {
  return useQueryAll(() => [
    custom.getProductProgress.useQuery([product.id, { apiVersion: "v1" }], {
      suspense: false,
    }),

    v3.getActivityProgress.useQuery([product.id], {
      enabled: product.identifier === "activity",
      select: (res) => res.data,
    }),
  ])
}
