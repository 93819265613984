import { wrapFetch } from "wrap-fetch"
import { Session } from "./activeSession"
import { getConfig } from "./config"
import { removeSession } from "./storage"

export const revokeSession = async (session: Session) => {
  const removedSessions = await removeSession(session)
  await Promise.all(removedSessions.map(revokeAccessToken))
}

const revokeAccessToken = async (session: Session) => {
  const { backendApiToken, backendUrl } = await getConfig()

  await wrapFetch({
    baseUrl: backendUrl,
    path: `/api/v1/oauth/revoke`,
    method: "POST",
    headers: {
      "API-Token": backendApiToken,
      Authorization: `Bearer ${session.accessToken}`,
    },
    data: {
      token: session.accessToken,
      token_type_hint: "access_token",
    },
  })
}
