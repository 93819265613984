import { v1, v3 } from "api"
import { auth } from "auth"
import { DEVICE_PLATFORM } from "src/constants"
import { getInstallAppLink } from "src/dataUtilities/productDataUtilities"
import { APP_ENV } from "src/env"
import { ConfigError } from "src/tools/ConfigError"
import { withQueryParams } from "utils"

type Product = v1["getProduct"]

export const getPlayLinks = async (productId: number) => {
  const product = await v1.getProduct.fetchQueryOnce([productId])

  const [startAppLink, launcherLink, installAppLink, webGLLink] =
    await Promise.all([
      getStartAppLink(product),
      getLauncherLink(product),
      getInstallAppLink(product),
      getWebGLLink(product),
    ])

  return { startAppLink, launcherLink, installAppLink, webGLLink }
}

const getStartAppLink = async (product: Product) => {
  const { platform, isAppPlatform } = DEVICE_PLATFORM

  if (!isAppPlatform) return
  if (!isPlatformSupported(product, platform)) return

  return addParams(product.content_link, product, platform)
}

const getLauncherLink = async (product: Product) => {
  const { platform, isLauncherPlatform } = DEVICE_PLATFORM

  if (!isLauncherPlatform) return
  if (!isPlatformSupported(product, platform)) return

  return addParams(product.launcher_link, product, platform)
}

const getWebGLLink = async (product: Product) => {
  const { isAppPlatform } = DEVICE_PLATFORM

  if (isAppPlatform) return
  if (!isPlatformSupported(product, "WebGL")) return

  return addParams(product.webgl_content_link, product, "WebGL")
}

const addParams = async (link: string, product: Product, platform: string) => {
  const { accessToken } = await auth.getActiveSession()
  const portalRedirectPath = getPortalRedirectPath(product)

  const { api_token, company } = await getParamsForProduct(product.id, platform)
  if (api_token === undefined) return

  const params = {
    api_token,
    product: product.token,
    company: company.name_key,
    env: APP_ENV,
    portalRedirectPath,
    accessToken,
  }

  return withQueryParams(link, params)
}

const getPortalRedirectPath = (product: Product) => {
  if (product.product_type === "skill") {
    return window.location.pathname
  }
}

const getParamsForProduct = async (product_id: number, platform: string) => {
  const { data } = await v3.getProduct.fetchQueryOnce([
    product_id,
    { include: ["oauth_applications", "company"] },
  ])

  const app = data.oauth_applications?.find((app) =>
    isPlatformEqual(app.platform, platform)
  )
  const api_token = app?.api_token

  const company = data.company

  if (!company) {
    throw new ConfigError("Product is missing company")
  }

  return { api_token, company }
}

const isPlatformSupported = (product: Product, platform: string) => {
  return product.supported_platforms.find((supportedPlatform) =>
    isPlatformEqual(supportedPlatform, platform)
  )
}

const isPlatformEqual = (left: string, right: string) => {
  return left.toLowerCase().trim() === right.toLowerCase().trim()
}
