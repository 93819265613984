import { v1 } from "api"
import { t } from "i18next"
import { custom } from "src/bonzai/bonzai"
import { exists } from "utils"

const DEPARTMENT_SCOPE = "department"

type Department = v1["getDepartmentsBranch"][number]

type LeaderboardScope = {
  name: string
  value: string
}

export const getLeaderboardScopes = async () => {
  const departments = await v1.getDepartmentsBranch()

  const scopes = flattenDepartments(departments).map(getScope)

  return [await getGlobalScope(), ...scopes].filter(exists)
}

const flattenDepartments = (departments: Department[]): Department[] => {
  return departments.flatMap((department) => [
    department,
    ...flattenDepartments(department.children),
  ])
}

const getScope = (department: Department) => {
  const value = getScopeValue(department)

  if (value === undefined) return undefined

  return {
    name: department.name,
    value,
  } satisfies LeaderboardScope
}

const getGlobalScope = async () => {
  const flags = await custom.getPortalConfigurations.fetchQueryOnce()
  if (flags.disable_global_leaderboard) return

  return {
    name: t("leaderboard.GLOBAL_SCOPE"),
    value: "company",
  } satisfies LeaderboardScope
}

const getScopeValue = ({ children, tags }: Department) => {
  const isLeafNode = children.length === 0

  if (!isLeafNode) return tags[0]

  return tags[0] ?? DEPARTMENT_SCOPE
}
