import { v1 } from "api"
import { useModal, useQueryParam, useRouteParamNumber } from "hooks"
import { createPlaythrough } from "src/bonzai/createPlaythrough"
import { getPreferredUpload } from "src/dataUtilities/productDataUtilities"
import { usePickText } from "src/i18n/usePickText"
import { Loader, ViewerModal } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

type PdfModalLoaderProps = {
  closeRoute: string
}
export const PdfModalLoader = ({ closeRoute }: PdfModalLoaderProps) => {
  const productId = useRouteParamNumber("productId")

  const { onClose } = useModal(closeRoute, () => {
    createPlaythrough(productId, {
      mutate: false,
      optimisticOneStar: true,
      invalidateDependencies: true,
    })
  })

  return (
    <ViewerModal onClose={onClose}>
      <QueryBoundary fallback={<Loader />}>
        <Load productId={productId} onClose={onClose} />
      </QueryBoundary>
    </ViewerModal>
  )
}

type PdfViewerLoaderProps = {
  productId: number
  onClose: () => void
}
const Load = ({ productId, onClose }: PdfViewerLoaderProps) => {
  const pickText = usePickText()

  const product = v1.getProduct.useQuery([productId])

  const [locale] = useQueryParam("locale")

  const pdfSrc = getPreferredUpload(product, locale)

  return (
    <ViewerModal.FullScreen
      headerText={pickText(product.titles)}
      src={pdfSrc}
      onClose={onClose}
    />
  )
}
