import { v3 } from "api"
import i18next from "i18next"
import { PORTAL_LOCALE_KEY } from "./constants"
import { loadLocales } from "./loadLocales"

export const changeLocale = async (locale: string) => {
  loadLocales([locale])
  i18next.changeLanguage(locale)

  await updateServerLocale(locale)
}

const updateServerLocale = async (locale: string) => {
  const userSettings = await v3.getUserSettings()
  const locales = {
    ...userSettings.data.locales,
    [PORTAL_LOCALE_KEY]: locale,
  }
  await v3.updateUserSettings({ locales })
}
