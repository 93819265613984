import { Session } from "./activeSession"
import { revokeSession } from "./revokeSession"

const SESSIONS_KEY = "stored_sessions_v1"
type AddSessionToStorage = {
  session: Session
  rememberMe: boolean
}
export const addSession = async ({
  session,
  rememberMe,
}: AddSessionToStorage): Promise<void> => {
  const removedSessions = await removeSession(session)
  await Promise.all(removedSessions.map(revokeSession))

  if (rememberMe) {
    await addPersistentSession(session)
  } else {
    await addTemporarySession(session)
  }
}

export const removeSession = async (session: Session): Promise<Session[]> => {
  const [removedTemporarySessions, removedPersistantSessions] =
    await Promise.all([
      removeTemporarySession(session),
      removePersistentSession(session),
    ])
  return [...removedTemporarySessions, ...removedPersistantSessions]
}

export const getSessions = async (): Promise<Session[]> => {
  const [persistentSessions, temporarySessions] = await Promise.all([
    getPersistentSessions(),
    getTemporarySessions(),
  ])
  return [...temporarySessions, ...persistentSessions]
}

const addPersistentSession = async (session: Session): Promise<void> => {
  localStorage.setItem(
    SESSIONS_KEY,
    JSON.stringify([session, ...(await getPersistentSessions())])
  )
}

const addTemporarySession = async (session: Session): Promise<void> => {
  sessionStorage.setItem(
    SESSIONS_KEY,
    JSON.stringify([session, ...(await getTemporarySessions())])
  )
}

const removePersistentSession = async (session: Session) => {
  const sessions = await getPersistentSessions()
  const removedSessions = sessions.filter(isSessionFilter(session))
  localStorage.setItem(
    SESSIONS_KEY,
    JSON.stringify(sessions.filter(isNotSessionFilter(session)))
  )
  return removedSessions
}

const removeTemporarySession = async (session: Session) => {
  const sessions = await getTemporarySessions()
  const removedSessions = sessions.filter(isSessionFilter(session))
  sessionStorage.setItem(
    SESSIONS_KEY,
    JSON.stringify(sessions.filter(isNotSessionFilter(session)))
  )
  return removedSessions
}

const getPersistentSessions = async (): Promise<Session[]> => {
  const item = localStorage.getItem(SESSIONS_KEY) ?? "[]"
  return JSON.parse(item) as Session[]
}

const getTemporarySessions = async (): Promise<Session[]> => {
  const item = sessionStorage.getItem(SESSIONS_KEY) ?? "[]"
  return JSON.parse(item) as Session[]
}

const isSessionFilter = (sessionA: Session) => (sessionB: Session) =>
  isSession(sessionA, sessionB)

const isNotSessionFilter = (sessionA: Session) => (sessionB: Session) =>
  !isSession(sessionA, sessionB)

const isSession = (sessionA: Session, sessionB: Session) => {
  if (sessionA.userId !== sessionB.userId) {
    return false
  }
  if (sessionA.subdomain !== sessionB.subdomain) {
    return false
  }
  if (sessionA.companyNameKey !== sessionB.companyNameKey) {
    return false
  }
  return true
}
