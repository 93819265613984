import { v1, v3 } from "api"
import { useQueryAll } from "bonzai"
import { useTranslation } from "react-i18next"
import { useFormatUser } from "src/bonzai/useFormatUser"
import { formatNumber } from "src/dataUtilities/formatNumber"
import { getMaxScore } from "src/dataUtilities/getMaxScore"
import { getProductLink } from "src/dataUtilities/productDataUtilities"
import { usePickText } from "src/i18n/usePickText"
import { LeaderboardEntry, LeaderboardWidget } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

export const LeaderboardWidgetLoader = () => (
  <QueryBoundary fallback={<LeaderboardWidget.Skeleton />}>
    <Load />
  </QueryBoundary>
)

const Load = () => {
  const { t, i18n } = useTranslation()

  const [product, highScores] = useData()

  const formatUser = useFormatUser()
  const pickText = usePickText()

  if (!product || !highScores) return null

  const maxScore = getMaxScore(highScores.entries)
  const productType = product.product_type.identifier
  const showMyEntry = highScores.my_entry.value > 0

  const items = highScores.entries.map((entry, index) => (
    <LeaderboardEntry
      key={index}
      image={entry.user.image}
      isFirst={index === 0}
      maxScore={maxScore}
      name={formatUser(entry.user)}
      rank={entry.rank}
      score={entry.value}
      scoreFormatted={formatNumber(i18n.language, entry.value)}
    />
  ))

  return (
    <LeaderboardWidget link={{ to: getProductLink(product.id, productType) }}>
      <LeaderboardWidget.Header
        title={pickText(product.title)}
        caption={t("leaderboard.PEOPLE_ARE_PLAYING")}
      />
      <LeaderboardWidget.Entries>
        {items}
        {showMyEntry && (
          <LeaderboardEntry
            image={highScores.my_entry.user.image}
            isFirst={false}
            maxScore={maxScore}
            name={formatUser(highScores.my_entry.user)}
            rank={highScores.my_entry.rank}
            score={highScores.my_entry.value}
            scoreFormatted={formatNumber(
              i18n.language,
              highScores.my_entry.value
            )}
          />
        )}
      </LeaderboardWidget.Entries>
    </LeaderboardWidget>
  )
}

const useData = () => {
  const product_id = v1.getMostPlayedProducts.useQuery([{ length: 1 }], {
    select: (res) => res.products[0]?.product_id,
  })

  return useQueryAll(() => [
    v3.getProduct.useQuery([product_id!], {
      enabled: product_id !== undefined,
      select: (res) => res.data,
    }),

    v1.getHighScores.useQuery(
      [{ product_id: product_id!, scope: "company", length: 4 }],
      { enabled: product_id !== undefined }
    ),
  ])
}
